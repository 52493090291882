import React from "react";
import Avatar from "@material-ui/core/Avatar";

function AvatarCustom(props) {
  let { chat, width, avatarSize, showStatus } = props;
  return (
    <div
      className={`${avatarSize ? avatarSize : "avatar-xs"} mx-auto d-block chat-user-img align-self-center me-3 ms-0 ${chat.userDestino ? chat.userDestino.status : ""
        } ${showStatus ? showStatus : ""
        }`}
    >
      {/*chat.isAtendimento ? (*/
        chat.pfpUrl !== undefined ? (
          <React.Fragment>
            {/* <span
              className="avatar-title rounded-circle bg-soft-primary text-primary"
              style={{ userSelect: "none" }}
            > */}
            {width ? (
              <Avatar alt="user-avatar" src={chat.pfpUrl} style={{ width: width, height: width }} />
            ) : (
              <Avatar alt="user avatar" src={chat.pfpUrl} />
            )}
            {/* </span> */}
            {
              showStatus && (
                <span className="user-status"></span>
              )
            }
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span
              className="avatar-title rounded-circle bg-soft-primary text-primary"
              style={{ userSelect: "none" }}
            >
              {chat.userDestino
                ? chat.userDestino.name.charAt(0).toUpperCase()
                : chat.name.charAt(0).toUpperCase()}
            </span>
          </React.Fragment>
        )
      }
    </div>
  );
}

export default React.memo(AvatarCustom);

import React from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import jwtService from "src/services/jwt.service";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { useLoadContext } from "src/contexts/LoadContext";
import Avatar from "@material-ui/core/Avatar";

function DropdownComponent({ id, isDropOpen, toggleDrop, dropClassName, toggleTab, history }) {
  const { user, setUser } = useLoadContext();
  return (
    <Dropdown
      nav
      id={id}
      className={dropClassName}
      isOpen={isDropOpen}
      toggle={toggleDrop}
    >
      <DropdownToggle className="nav-link" style={{ "display": "flex", "justifyContent": "center" }} tag="a">
        <Avatar
          src={user && user.avatar ? user.avatar : avatar1}
          alt=""
        />
      </DropdownToggle>
      <DropdownMenu>
        {user && ["suporte", "dev", "comercial"].includes(user.setor) && (
          <React.Fragment>
            {/* <ModalNovaConversa setShow={setModal} show={modal} />
            <DropdownItem
              style={{ padding: 10 }}
              onClick={() => setModal(true)}
            >
              Nova Conversa <i className="ri-chat-new-line float-end text-muted"></i>
            </DropdownItem> */}
            <DropdownItem
              style={{ padding: 10 }}
              onClick={() => {
                toggleTab("profile");
                history.push("/profile");
              }}
            >
              Perfil <i className="ri-profile-line float-end text-muted"></i>
            </DropdownItem>
            {user.isAdmin && user.setor === "suporte" && (
              <DropdownItem
                style={{ padding: 10 }}
                onClick={() => {
                  toggleTab("config");
                  history.push("/config");
                }}
              >
                Configurações{" "}
                <i className="ri-settings-3-line float-end text-muted"></i>
              </DropdownItem>
            )}
            <DropdownItem divider />
          </React.Fragment>
        )}
        <DropdownItem
          style={{ padding: 10 }}
          onClick={() => {
            jwtService
              .logout()
              .then((response) => {
                setUser(undefined);
                // history.push("/");

                // window.location.reload();
                window.location.reload(false);
              })
              .catch((err) => console.log(err));
          }}
        >
          Deslogar{" "}
          <i className="ri-logout-circle-r-line float-end text-muted"></i>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown >
  );
}

export default React.memo(DropdownComponent);

import BaseResourceService from "src/services/BaseResourceService";
import messageService from "src/services/message.service";
import moment from 'moment'

class ConversaService extends BaseResourceService {
  endpoint = "conversa";

  //#region comercial
  trocarNumeroConversa = async (numeroNovo, numeroAntigo) => {
    let params = [];
    params.push({ chave: 'numeroNovo', valor: numeroNovo });
    params.push({ chave: 'numeroAntigo', valor: numeroAntigo });
    return this.getAll(this.endpoint + "/trocarNumeroConversa", params);
  };

  conversasInativasNotificar = async () => {
    let params = [];
    return this.getAll(this.endpoint + "/conversas_inativas_notificar", params);
  };

  finalizarAtendimentoPorInatividade = async (params) => { 
    return this.post(this.endpoint + "/finalizar_atendimento_inatividade", params);
  };

  cancelarNotificacaoPorInatividade = async (mensagemId) => {
    let params = [];
    params.push({ chave: 'mensagemId', valor: mensagemId });
    return this.getAll(this.endpoint + "/cancelar_notificacao_Inatividade", params);
  };

  countNaoLidos = async (tipo, userId) => {
    let params = [];
    params.push({ chave: 'tipo', valor: tipo });
    params.push({ chave: 'userId', valor: userId });
    return this.getAll(this.endpoint + "/count_nao_lidos", params);
  };

  verificaConversa = async (conversaId) => {
    let params = [];
    params.push({ chave: 'conversaId', valor: conversaId });
    return this.getAll(this.endpoint + "/verifica_conversa", params);
  };

  getAllAtendimentoComercialEmEspera = async (filter, sort, user) => {
    let params = [];
    params.push({ chave: 'searchText', valor: filter ? filter : '' });
    params.push({ chave: 'sort', valor: sort });
    params.push({ chave: 'isNegociacao', valor: false });
    params.push({ chave: 'isNegociado', valor: false });
    if (user)
      params.push({ chave: 'userId', valor: user });
    return this.getAll(this.endpoint + "/atendimentos_em_espera", params);
  };

  getAllAtendimentoComercialEmContato = async (filter, sort, user, filtroNaoRespondidos) => {
    let params = [];
    params.push({ chave: 'searchText', valor: filter ? filter : '' });
    params.push({ chave: 'sort', valor: sort });
    params.push({ chave: 'emContato', valor: true });
    if (filtroNaoRespondidos)
      params.push({ chave: 'lastMsgFrom', valor: 'contato' });
    // params.push({ chave: 'isNegociado', valor: undefined });
    if (user)
      params.push({ chave: 'userId', valor: user });
    return this.getAll(this.endpoint + "/atendimentos_em_contato", params);
  };

  getAllAtendimentoComercialNegociando = async (filter, sort, user, filtroNaoRespondidos) => {
    let params = [];
    params.push({ chave: 'searchText', valor: filter ? filter : '' });
    params.push({ chave: 'sort', valor: sort });
    params.push({ chave: 'isNegociacao', valor: true });
    if (filtroNaoRespondidos)
      params.push({ chave: 'lastMsgFrom', valor: 'contato' });
    if (user)
      params.push({ chave: 'userId', valor: user });
    return this.getAll(this.endpoint + "/atendimentos_em_contato", params);
  };

  getAllAtendimentoComercialFechado = async (filter, sort, user, filtroNaoRespondidos) => {
    let params = [];
    params.push({ chave: 'searchText', valor: filter ? filter : '' });
    params.push({ chave: 'sort', valor: sort });
    params.push({ chave: 'isNegociado', valor: true });
    if (filtroNaoRespondidos)
      params.push({ chave: 'lastMsgFrom', valor: 'contato' });
    if (user)
      params.push({ chave: 'userId', valor: user });
    return this.getAll(this.endpoint + "/atendimentos_em_contato", params);
  };

  getAllAtendimentoComercialInativas = async (filter, sort, user, filtroNaoRespondidos) => {
    let params = [];
    params.push({ chave: 'searchText', valor: filter ? filter : '' });
    params.push({ chave: 'sort', valor: sort });
    params.push({ chave: 'isInativo', valor: true });
    params.push({ chave: 'isFinalizado', valor: false });
    if (filtroNaoRespondidos)
      params.push({ chave: 'lastMsgFrom', valor: 'contato' });
    if (user)
      params.push({ chave: 'userId', valor: user });
    const ret = this.getAll(this.endpoint + "/atendimentos_em_contato", params);
    return ret;
  };

  iniciarAtendimentoComercial = async (chatId) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/iniciar_atendimento_comercial`, { chatId })
        .then((response) => {
          if (response) {
            messageService.successMessage(
              "Atendimento iniciado com sucesso!"
            );
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  emNegociacaoAtendimentoComercial = async (conversaId) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/negociacao_atendimento_comercial`, { conversaId })
        .then((response) => {
          if (response) {
            messageService.successMessage(
              "Atendimento foi transferido para em Negociação!"
            );
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  fecharNegociacaoAtendimentoComercial = async (conversaId) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/fechar_negociacao_atendimento_comercial`, { conversaId })
        .then((response) => {
          if (response) {
            messageService.successMessage(
              "A negociação foi concluída!"
            );
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  descartarAtendimento = async (
    conversaId,
    categorias,
    userId,
    limparInativas = false) => {
    console.log({ userId })
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/descartar_atendimento_comercial`,
        {
          conversaId,
          categorias,
          limparInativas,
          userId
        })
        .then((response) => {
          if (response) {
            messageService.successMessage(
              "Atendimento finalizado com sucesso!"
            );
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };
  //#endregion

  criarAvulso = async (body) => {
    let url = this.addParamsToUrl(`/${this.endpoint}/criar_avulso`);
    return new Promise((resolve, reject) => {
      this.post(url, body)
        .then((response) => {
          if (response && response.data || response.data ==="") {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  };

  iniciarConversa = async (userDestinoId) => {
    let params = [];
    params.push({ chave: 'userDestinoId', valor: userDestinoId });
    let url = this.addParamsToUrl(`/${this.endpoint}/iniciar`, params);
    return new Promise((resolve, reject) => {
      this.get(url)
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  };

  removerUnread = async (conversaId) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/remove_unread`, { conversaId })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  getAllConversas = async (filter, sort) => {
    let params = [];
    params.push({ chave: 'searchText', valor: filter });
    params.push({ chave: 'sort', valor: sort });
    return this.getAll(this.endpoint + "/", params);
  };

  getAllConversasUsuario = async (filter, sort) => {
    let params = [];
    params.push({ chave: 'searchText', valor: filter });
    params.push({ chave: 'sort', valor: sort });
    return this.getAll(this.endpoint + "/atendimentos_usuario", params);
  };

  getAllAtendimentosFilaEspera = async (filter, sort) => {
    let params = [];
    params.push({ chave: 'searchText', valor: filter });
    params.push({ chave: 'sort', valor: sort });
    return this.getAll(this.endpoint + "/atendimentos_fila_espera", params);
  };

  getAllAtendimentosIniciados = async (filter, sort) => {
    let params = [];
    params.push({ chave: 'searchText', valor: filter });
    params.push({ chave: 'sort', valor: sort });
    return this.getAll(this.endpoint + "/atendimentos_iniciados", params);
  };

  getAllAtendimentosAbertos = async (dataIni, dataFim, atendente) => {
    let params = [];
    params.push({ chave: 'dataIni', valor: dataIni });
    params.push({ chave: 'dataFim', valor: dataFim });
    params.push({ chave: 'atendente', valor: atendente });
    return this.getAll(this.endpoint + "/atendimentos_abertos", params);
  };

  getAtendimentosAtendente = async (dataIni, dataFim, searchName, atendente) => {
    let params = [];
    params.push({ chave: 'dataIni', valor: dataIni });
    params.push({ chave: 'dataFim', valor: dataFim });
    params.push({ chave: 'searchName', valor: searchName });
    params.push({ chave: 'atendente', valor: atendente });
    return this.getAll(this.endpoint + "/atendimentos_group_atendente", params);
  };

  getAllAtendimentosCategoriaGroup = async (dataIni, dataFim, searchName, atendente) => {
    let params = [];
    params.push({ chave: 'dataIni', valor: dataIni });
    params.push({ chave: 'dataFim', valor: dataFim });
    params.push({ chave: 'searchName', valor: searchName });
    params.push({ chave: 'atendente', valor: atendente });
    return this.getAll(this.endpoint + '/atendimentos_group_categoria', params);
  };

  getAllAtendimentosConcluidosCount = async (dataIni, dataFim, searchName, atendente) => {
    let params = [];
    params.push({ chave: 'dataIni', valor: dataIni });
    params.push({ chave: 'dataFim', valor: dataFim });
    params.push({ chave: 'searchName', valor: searchName });
    params.push({ chave: 'atendente', valor: atendente });
    return this.getAll(this.endpoint + '/atendimentos_concluidos_count', params);
  };

  getAllAtendimentosByCategoria = async (categoria_id) => {
    let params = [];
    params.push({ chave: 'categoria_id', valor: categoria_id });
    return this.getAll(this.endpoint + '/atendimentos_categoria', params);
  };

  getAllAtendimentosByIdAtendente = async (userOwner, dataInicio, dataFinal) => {
    let params = [];
    params.push({ chave: 'dataInicio', valor: dataInicio ? moment(dataInicio).format('YYYY-MM-DD') : '' });
    params.push({ chave: 'dataFinal', valor: dataFinal ? moment(dataFinal).format('YYYY-MM-DD') : '' });
    params.push({ chave: 'userOwner', valor: userOwner });
    return this.getAll(this.endpoint + '/atendimentos_by_atendente', params);
  };

  getAllAtendimentosByIdChat = async (chat_id) => {
    let params = [];
    params.push({ chave: 'chat_id', valor: chat_id });
    return this.getAll(this.endpoint + "/atendimentos_chat_id", params);
  };

  getAllAtendimentos = async (filter, sort, dataInicio, dataFinal, atendente, setor = 'suporte',
    naoLidos, descartados, isNegociacao = false, isNegociado = false) => {
    let params = [];
    params.push({ chave: 'searchText', valor: filter });
    params.push({ chave: 'sort', valor: sort });
    params.push({ chave: 'dataInicio', valor: dataInicio ? moment(dataInicio).format('YYYY-MM-DD') : '' });
    params.push({ chave: 'dataFinal', valor: dataFinal ? moment(dataFinal).format('YYYY-MM-DD') : '' });
    params.push({ chave: 'atendente', valor: atendente });
    params.push({ chave: 'naoLidos', valor: naoLidos });
    params.push({ chave: 'descartados', valor: descartados });

    if (setor === 'comercial') {
      params.push({ chave: 'isNegociacao', valor: isNegociacao });
      params.push({ chave: 'isNegociado', valor: isNegociado });
      return this.getAll(this.endpoint + "/atendimentos", params);
    }
    return this.getAll(this.endpoint + "/atendimentos", params);
  };

  getAllAtendimentosMediaEspera = async (dataInicio, dataFinal) => {
    let params = [];
    params.push({ chave: 'dataInicio', valor: dataInicio ? moment(dataInicio).format('YYYY-MM-DD') : '' });
    params.push({ chave: 'dataFinal', valor: dataFinal ? moment(dataFinal).format('YYYY-MM-DD') : '' });
    return this.getAll(this.endpoint + "/atendimentos_media_espera", params);
  };
  getAllAtendimentosEmAtraso = async (dataInicio, dataFinal) => {
    let params = [];
    params.push({ chave: 'dataInicio', valor: dataInicio ? moment(dataInicio).format('YYYY-MM-DD') : '' });
    params.push({ chave: 'dataFinal', valor: dataFinal ? moment(dataFinal).format('YYYY-MM-DD') : '' });
    return this.getAll(this.endpoint + "/atendimentos_em_atraso", params);
  };
  getAllAtendimentosAgendados = async (dataInicio, dataFinal) => {
    let params = [];
    params.push({ chave: 'dataInicio', valor: dataInicio ? moment(dataInicio).format('YYYY-MM-DD') : '' });
    params.push({ chave: 'dataFinal', valor: dataFinal ? moment(dataFinal).format('YYYY-MM-DD') : '' });
    return this.getAll(this.endpoint + "/atendimentos_agendados", params);
  };

  getAllAgendamentos = async (filter, sort) => {
    let params = [];
    params.push({ chave: 'searchText', valor: filter });
    params.push({ chave: 'sort', valor: sort });
    return this.getAll(this.endpoint + "/agendamentos", params);
  };

  getByCodigo = async (id) => {
    return this.findModelo(this.endpoint, id);
  };

  iniciarAtendimento = async (chatId) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/iniciar_atendimento`, { chatId })
        .then((response) => {
          if (response) {
            messageService.successMessage(
              "Atendimento iniciado com sucesso!"
            );
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  criarAtendimento = async (conversaId) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/criar_atendimento`, { conversaId })
        .then((response) => {
          if (response) {
            messageService.successMessage(
              "Atendimento iniciado com sucesso!"
            );
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  agendarAtendimento = async (chatId, dataAgendamento, horarioAgendamento, motivoAgendamento, isPlantao = false) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/agendar_atendimento`, { chatId, dataAgendamento, horarioAgendamento, motivoAgendamento, isPlantao })
        .then((response) => {
          if (response) {
            messageService.successMessage(
              "Atendimento agendado com sucesso!"
            );
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  finalizarAtendimento = async (
    conversaId,
    obsProblema = null,
    obsSolucao = null,
    categorias = null,
    empresa = null,
    sistema = null,
    versao = null,
    isCancelamento = false) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/finalizar_atendimento`,
        {
          conversaId,
          obsProblema,
          obsSolucao,
          categorias,
          empresa,
          sistema,
          versao,
          isCancelamento
        })
        .then((response) => {
          if (response) {
            messageService.successMessage(
              "Atendimento finalizado com sucesso!"
            );
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  transferirAtendimento = async (conversaId, novoUserId) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/transferir`, { conversaId, novoUserId })
        .then((response) => {
          if (response) {
            messageService.successMessage(
              "Atendimento transferido com sucesso!"
            );
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

  arquivarConversa = async (conversaId) => {
    return new Promise((resolve, reject) => {
      this.saveOrUpdate(`/${this.endpoint}/arquivar`, { conversaId })
        .then((response) => {
          if (response) {
            messageService.successMessage(
              "Conversa arquivada com sucesso!"
            );
            resolve(response.data);
          } else {
            this.tratarErrosToast(response);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          this.tratarErrosToast(error.response);
          reject(error.response);
        });
    });
  };

}

const sevice = new ConversaService();
export default sevice;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LeftSidebarMenu from "./LeftSidebarMenu";

class Index extends Component {
  constructor(props) {
    super(props);
    this.capitalizeFirstLetter.bind(this);
  }

  state = {
    avatar: undefined,
  };

  //function for capital first letter of current page pathname
  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  async componentDidMount() {
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname);
    //set document title according to page path name
    document.title = currentage + " | GermanZap++";
  }

  render() {
    return (
      <React.Fragment>
        <div className="layout-wrapper d-lg-flex">
          {/* left sidebar menu */}
          <LeftSidebarMenu
            history={this.props.history}
            avatar={this.state.avatar}
          />
          {/* render page content */}
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Index);

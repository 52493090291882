import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import AvatarCustom from "./UserChat/AvatarCustom";
import moment from "moment";
import "moment/locale/pt-br";
import { getValue, getValueEnd } from "src/utils/util-get-values-string";
import { useLoadContext } from "src/contexts/LoadContext";
import ConversaService from "src/services/conversa/conversa.service";

function ChatsItem(props) {
  let { chat, activeChat, openUserChat, isTabTodas, agendamentoResposta, openAllSchedulesForNumber } = props;
  const { isPlantao, plantaoAtivado, user } = useLoadContext();
  const [minDiff, setminDiff] = useState();

  const [refreshDiff, setRefreshDiff] = useState(0);

  function calcDiff() {
    let dt = chat.createdAt;
    const oito = moment().set("hour", 8).set("minute", 0);
    const dezOntem = moment().set("hour", 22).set("minute", 0).set("days", -1);
    if (moment(chat.createdAt).isBetween(moment(dezOntem), moment(oito)))
      dt = oito;
    setminDiff(
      moment().diff(chat.isAgendamento ? chat.dataAgendamento : dt, "minutes")
    );
  }

  useEffect(() => {
    calcDiff();
    // console.log(chat)
    // eslint-disable-next-line
  }, [chat.isAgendamento, chat.dataAgendamento, chat.createdAt]);

  useEffect(() => {
    setInterval(() => {
      setRefreshDiff(refreshDiff + 1);
      calcDiff();
    }, 1000 * 30 * 1);
    // eslint-disable-next-line
  }, []);

  const getMsg = (text) => {
    if (chat.chat.lastMsgText.includes("VCARD")) {
      let a = text.split("\n");
      let name = getValue("N:;", ";;;", text, 3);
      let number = "";
      a.forEach((item) => {
        if (item.includes("waid=")) number = getValueEnd("waid=", item, 5);
        if (item.includes("N:;") && item.includes(";;;"))
          name = getValue("N:;", ";;;", item, 3);
        else if (item.includes("FN:")) name = getValueEnd("FN:", item, 3);
      });
      return `Contato: ${name} ${number.split(":")[1]}`;
    } else {
      return text;
    }
  };

  async function verificaChat(activeChat) {
    await ConversaService.verificaConversa(activeChat._id).then((e) => {});
  }

  return (
    <li
      key={chat._id}
      id={"conversation" + chat._id}
      className={
        (activeChat && activeChat._id === chat._id ? "active " : " ") +
        (chat.unreadMsg > 0 ? "unread " : " ") +
        (chat.isTyping ? "typing" : "")
      }
    >
      <Link
        to="#"
        onClick={(e) => {
          if (activeChat || openUserChat) openUserChat(e, chat);
          if (agendamentoResposta) openAllSchedulesForNumber(agendamentoResposta);
        }}
      >
        <div className="d-flex">
          <AvatarCustom
            chat={{
              ...chat,
              pfpUrl: chat.userDestino ? chat.userDestino.avatar : chat.pfpUrl,
            }}
            width={48}
            avatarSize="avatar-sm"
          />
          <div className="flex-1 overflow-hidden">
            <Grid container direction="row" alignContent="space-between">
              <Col>
                <h5 className="text-truncate font-size-15 mb-1">
                  {chat.isAvulso
                    ? `${
                        chat.nomeRequisitante
                          ? `${
                              chat.userRequisitante
                                ? chat.userRequisitante.name
                                : ""
                            } | ${chat.nomeRequisitante}`
                          : ""
                      }`
                    : chat.userDestino
                    ? chat.userDestino.name
                    : chat.name}
                </h5>
              </Col>
              <Col style={{ float: "right" }}>
                <div
                  id={"unRead" + chat.id}
                  style={{ float: "right" }}
                  className="unread-message"
                >
                  {
                    chat && chat.setor === "comercial" && agendamentoResposta ? (
                      <div>
                        {agendamentoResposta.mensagem}
                      </div>
                    ) : (
                      chat && !chat.isVerificado && chat.setor === "comercial" && (
                        <span
                          style={{ float: "left" }}
                          onClick={() => verificaChat(chat)}
                        >
                          ⚠️
                        </span>
                    )
                  )}
                  {chat.unreadMsg === 0 ? null : (
                    <span
                      className="badge badge-soft-danger rounded-pill"
                      style={{ float: "right" }}
                    >
                      {chat.unreadMsg}
                    </span>
                  )}
                </div>
              </Col>
            </Grid>

            <div className="chat-user-message text-truncate mb-0">
              <Grid container direction="row" alignContent="space-between">
                <Col>
                  {chat.isTyping ? (
                    <>
                      typing
                      <span className="animate-typing">
                        <span className="dot ms-1"></span>
                        <span className="dot ms-1"></span>
                        <span className="dot ms-1"></span>
                      </span>
                    </>
                  ) : (
                    <>
                      {chat.chat && (
                        <>
                          {chat.chat.isLastMsgImage ? (
                            <span>
                              <i className="ri-image-fill align-middle me-1"></i>
                              Imagem
                            </span>
                          ) : chat.chat.isLastMsgFile ? (
                            <span>
                              <i className="ri-file-list-3-line me-1"></i>
                              Documento
                            </span>
                          ) : chat.chat.isLastMsgAudio ? (
                            <span>
                              <i className="ri-mic-line me-1"></i>
                              Audio
                            </span>
                          ) : chat.chat.isLastMsgSticker ? (
                            <span>
                              <i className="ri-mickey-fill me-1"></i>
                              Figurinha
                            </span>
                          ) : chat.chat.lastMsgText ? (
                            <div
                              className="text-truncate"
                              style={{
                                width: props.widthText
                                  ? props.widthText
                                  : "100%",
                              }}
                            >
                              {getMsg(chat.chat.lastMsgText)}
                            </div>
                          ) : null}
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Grid>
            </div>
            <div className="chat-user-message text-truncate mb-0">
              <Grid container direction="row" alignContent="space-between">
                {isTabTodas ? (
                  <Col>
                    <div style={{ color: "yellow" }}>
                      {chat.userOwner ? chat.userOwner.name : ""}
                    </div>
                  </Col>
                ) : (
                  ""
                )}
                {/* <span style={{ color: '#00FFFF', marginRight: 5 }}>
                  {
                    moment(chat.createdAt).format('HH:mm')
                  }
                </span> */}
                {!isTabTodas && chat.isAgendamento && !chat.userOwner ? (
                  <Col>
                    <div style={{ color: "yellow" }}>
                      {chat.dataAgendamento
                        ? moment(chat.dataAgendamento).format(
                            "DD/MM/YYYY HH:mm"
                          )
                        : ""}
                    </div>
                  </Col>
                ) : (
                  ""
                )}
                {((isTabTodas || chat.userOwner !== null) &&
                  chat.inicioAtendimento) ||
                user.setor === "comercial" ? (
                  <Col>
                    {user.setor === "comercial" ? (
                      <>
                        {
                          agendamentoResposta ? (
                            <div style={{ color: "cyan" }}>
                              {moment(agendamentoResposta.horarioAgendamento).format("DD/MM/YYYY HH:mm")}
                            </div>
                          ) : (
                            <div style={{ color: "cyan" }}>
                              {moment(chat.lastMsg).fromNow()}
                            </div>
                          )
                        }
                      </>
                    ) : (
                      <div style={{ color: "cyan" }}>
                        {"Iniciado " + moment(chat.inicioAtendimento).fromNow()}
                      </div>
                    )}
                  </Col>
                ) : (
                  ""
                )}
                <span style={{ color: "transparent" }}>{refreshDiff}</span>
                {chat.isFilaEspera && !chat.userOwner && (
                  <Col>
                    <Chip
                      size="small"
                      // variant="filled"
                      label={
                        <Typography variant="button">
                          {user.setor === "suporte"
                            ? !chat.isAvulso
                              ? isPlantao() && plantaoAtivado
                                ? minDiff < 30
                                  ? "EM ESPERA"
                                  : minDiff < 45
                                  ? "ATRASADO"
                                  : "MT ATRASADO"
                                : minDiff < 10
                                ? "EM ESPERA"
                                : minDiff < 20
                                ? "ATRASADO"
                                : "MT ATRASADO"
                              : "AVULSO"
                            : minDiff < 1440
                            ? "EM ESPERA"
                            : "ATRASADO"}
                        </Typography>
                      }
                      color={
                        chat.isAvulso
                          ? "#fff"
                          : minDiff < 10
                          ? "primary"
                          : minDiff < 20
                          ? "primary"
                          : "secondary"
                      }
                      style={{
                        paddingTop: 2,
                        justifyContent: "flex-start",
                        float: "right",
                        color:
                          minDiff < 10
                            ? "#2c7021"
                            : minDiff < 20
                            ? "#ed3939"
                            : "#5e0000",
                      }}
                    />
                  </Col>
                )}
              </Grid>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
}

export default React.memo(ChatsItem);
